import { ActionState } from '../../actions/types';

export type KohaInstanceUserData = {
    'category_id': string,
    'library_id': string,
    'updated_on': Date,
    'fullname': string,
    'email': string,
    'patron_id': number,
    'cardnumber': string
};


export const objectToUserData: (o: object) => KohaInstanceUserData | null = (o) => {
    try {
        const o0: any = o;
        return {
            "category_id": o0.category_id,
            "library_id": o0.library_id,
            "updated_on": new Date(Date.parse(o0.updated_on)),
            "fullname": o0.fullname,
            "email": o0.email,
            "patron_id": o0.patron_id,
            "cardnumber": o0.cardnumber
        };
    } catch (e) {
        return null;
    }
};

export type PatronData = Map<string, KohaInstanceUserData>;

export type PatronDataState = {
    data: PatronData,
    fetch: ActionState
}

export const initialPatronDataState: PatronDataState = {
    data: new Map(),
    fetch: {
        loading: false,
        loaded: false,
        error: null
    }
};

