import { createContext, useState, ComponentType, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Api } from '@plone/volto/helpers';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { OpenSearchRecord, openSearchRecordFromResponse } from './Record';
import { MarcSpecCollection } from '@kreablo/marc-spec';

export enum RecordStateEnum {
    Error,
    Loading,
    Empty,
    Record
};

export type RecordStateData = string | null | ComponentType | [string, string]

export type RecordState = {
    state: RecordStateEnum,
    recordId: string | undefined,
    data: RecordStateData,
    marcSpecCollection: MarcSpecCollection
};

export const initialContext: RecordState = {
    state: RecordStateEnum.Empty,
    recordId: undefined,
    data: null,
    marcSpecCollection: new MarcSpecCollection()
};

export const RecordContext: React.Context<RecordState> = createContext(initialContext);

type RecordStateProviderProps = {
    recordState: RecordState,
    children: ReactNode
};

const errorState: (rs: RecordState, recordid: string, message: string) => RecordState = (rs, recordid, message) => {
    return {
        state: RecordStateEnum.Error,
        recordId: recordid,
        data: message,
        marcSpecCollection: rs.marcSpecCollection
    };
}

export function useRecordState(recordId: string | undefined, recordBase64: string | undefined, marcSpecCollection: MarcSpecCollection): RecordState {
    const [template_recordid, redux_recordid, opensearch_record, doc_recordid]: any = useSelector((state: any) => [
        state?.content?.data?.postidentitet_kohainstansbiblionumber,
        state?.reduxAsyncConnect?.content?.postidentitet_kohainstansbiblionumber,
        state?.content?.data?.yokohama_marc_data_base64,
        state?.content?.data?.yokohama_recordid
    ]);

    const [active_recordid, active_record] = recordId !== undefined
        ? [recordId, typeof recordBase64 === "string" ? recordBase64 : null]
        : [typeof template_recordid === "string" ? template_recordid : (typeof doc_recordid === "string" ? doc_recordid : redux_recordid), opensearch_record];

    /* TODO registry variable is used for debugging. Remove later. */
    const registry = useSelector((state) => state);

    const initial_state: RecordState = {
        state: typeof active_record === "string" ? RecordStateEnum.Record : RecordStateEnum.Empty,
        recordId: active_recordid,
        marcSpecCollection: marcSpecCollection,
        data: active_record
    };

    const [state, setState] = useState(initial_state);

    const setError: (recordid: string, message: string) => void = (recordid, message) => {
        const es = errorState(state, recordid, message);
        setState(es);
        return es;
    }

    useEffect(() => {
        if (typeof active_recordid !== 'string') {
            return;
        }
        const doLoad = state.state === RecordStateEnum.Empty || state.recordId !== active_recordid;

        if (!doLoad) {
            return;
        }

        const api = new Api();
        api.get('/@yokohama-record/' + active_recordid).then((response: object) => {
            if ("marc_data_base64" in response && Array.isArray(response.marc_data_base64) && response.marc_data_base64.length > 0) {
                marcSpecCollection.loadRecordBase64(response.marc_data_base64[0]);
                setState({
                    state: RecordStateEnum.Record,
                    recordId: active_recordid,
                    data: response.marc_data_base64[0],
                    marcSpecCollection: marcSpecCollection
                });

            } else {
                marcSpecCollection.reset();
                setError(active_recordid, "invalid response from record api");
            }
        }).catch((error: string) => {
            marcSpecCollection.reset();
            setError(active_recordid, error);
        });
        setState({
            state: RecordStateEnum.Loading,
            recordId: active_recordid,
            data: null,
            marcSpecCollection: marcSpecCollection
        });
    }, [active_recordid]);

    return state;
}

export const RecordStateProvider: (props: RecordStateProviderProps) => JSX.Element = (props) => {
    const {
        recordState,
        children
    } = props;

    const state = useRecordState(recordState.recordId,
        recordState.state === RecordStateEnum.Record && typeof recordState.data === "string" ? recordState.data : undefined,
        recordState.marcSpecCollection);

    if (state.state === RecordStateEnum.Record && typeof state.data === "string") {
        state.marcSpecCollection.loadRecordBase64(state.data);
    } else {
        state.marcSpecCollection.reset();
    }

    return (<RecordContext.Provider value={state}>
        {children}
    </RecordContext.Provider>);
};


export const MaybeRecordStateProvider = (props: any) => {
    const state = useRecordState(undefined, undefined, initialContext.marcSpecCollection);


    if (state.recordId !== undefined) {
        if (typeof state.data === "string") {
            state.marcSpecCollection.loadRecordBase64(state.data);
        }
        return (<RecordContext.Provider value={state}>
            {props.children}
        </RecordContext.Provider>);
    } else {
        state.marcSpecCollection.reset();
        return <>{props.children}</>;
    }
};
