import { GET_YOKOHAMA_RECORD } from '../constants/ActionTypes';

const initialState = {
  loaded: false,
  loading: false,
  error: null,
};

export default function yokohama_record(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_YOKOHAMA_RECORD}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_YOKOHAMA_RECORD}_SUCCESS`:
      return {
        ...state,
        ...action.result,
        error: null,
        loaded: true,
        loading: false,
      };
    case `${GET_YOKOHAMA_RECORD}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
