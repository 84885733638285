import { GET_YOKOHAMA_SEARCH_TEMPLATES } from '../constants/ActionTypes';
import { YokohamaSearchTemplatesState } from '../registry/searchtemplate';

export const initialState: YokohamaSearchTemplatesState = {
    tsm: new Map(),
    loading: false,
    error: undefined
};

export default function yokohama_search_templates(state: YokohamaSearchTemplatesState = initialState, action: object = {}): YokohamaSearchTemplatesState {
    if ("type" in action) {
        switch (action.type) {
            case `${GET_YOKOHAMA_SEARCH_TEMPLATES}_PENDING`:
                return {
                    tsm: state.tsm,
                    loading: true,
                    error: undefined
                };
            case `${GET_YOKOHAMA_SEARCH_TEMPLATES}_FAIL`:
                return {
                    tsm: state.tsm,
                    loading: false,
                    error: "error" in action && typeof action.error === "string" ? action.error : "Could load search templates."
                }
            case `${GET_YOKOHAMA_SEARCH_TEMPLATES}_SUCCESS`:
                if ("result" in action && Array.isArray(action.result)) {
                    for (const r of action.result) {
                        if (Array.isArray(r) && r.length >= 2) {
                            const instance = r[0];
                            const doc = r[1];
                            state.tsm.set(instance, doc);
                        }
                    }
                }

                return {
                    tsm: state.tsm,
                    loading: false,
                    error: undefined
                };
            default:
                break;
        }
    }
    return state;
};
