
import {
    FETCH_PATRON_DATA
} from '../constants/ActionTypes';

import { PatronDataState, initialPatronDataState as initialState, objectToUserData } from '../ils/patron/types';
import { LOGIN, LOGOUT } from '@plone/volto/constants/ActionTypes';

const patrondata: (state: PatronDataState, action: object) => PatronDataState = (state = initialState, action = {}) => {
    if (!("type" in action) || typeof action.type !== "string") {
        return state;
    }
    switch (action.type) {
        case `${LOGIN}_SUCCESS`:
        case `${LOGOUT}_SUCCESS`:
            return initialState;
        case `${FETCH_PATRON_DATA}_PENDING`:
            return {
                ...state,
                fetch: {
                    loading: true,
                    loaded: false,
                    error: null
                }
            };
        case `${FETCH_PATRON_DATA}_SUCCESS`:
            if ("result" in action
                && typeof action.result === "object"
                && action.result !== null
                && "koha_user_data" in action.result
                && typeof action.result.koha_user_data === "object") {
                const data = new Map();
                const r: any = action.result.koha_user_data;
                for (const instancename in r) {
                    const o = r[instancename];
                    const d = objectToUserData(o);
                    if (d !== null) {
                        data.set(instancename, d);
                    }
                }
                return {
                    data: data,
                    fetch: {
                        loading: false,
                        loaded: true,
                        error: null
                    }
                };
            } else {
                return {
                    ...state,
                    fetch: {
                        loading: false,
                        loaded: true,
                        error: "invalid respons"
                    }
                }
            }
        case `${FETCH_PATRON_DATA}_FAIL`:
            const error = "error" in action ? "" + action.error : "unknown error"
            return {
                ...state,
                fetch: {
                    loading: false,
                    loaded: true,
                    error: error
                }
            }

    }
    return state;
}

export default patrondata;



