import { PatronHoldsState, initialState, objectToHold } from '../ils/holds';
import { LOGIN, LOGOUT } from '@plone/volto/constants/ActionTypes';

import {
    PLACE_HOLD,
    REMOVE_HOLD,
    FETCH_PATRON_HOLDS
} from '../constants/ActionTypes';

const _c: <T> (m: Map<string, T>, k: string, v: T) => Map<string, T> = (m, k, v) => {
    const c = new Map(m);
    c.set(k, v);
    return c;
}

const n: (o: object) => string | undefined = (o) => {
    if ("instancename" in o && typeof o.instancename === "string") {
        return o.instancename;
    }
    return undefined;
}

const rn: (o: object) => [string, string] | undefined = (o) => {
    const n0 = n(o);
    if (n0 !== undefined && "hold_id" in o && typeof o.hold_id === "number") {
        return [n0, n0 + '/' + o.hold_id];
    }
    return undefined;
};

const pn: (o: object) => [string, string] | undefined = (o) => {
    const n0 = n(o);
    if (n0 !== undefined && "biblio_id" in o && typeof o.biblio_id === "number") {
        return [n0, n0 + '/' + o.biblio_id];
    }
    return undefined;
};

const holds: (state: PatronHoldsState, action: object) => PatronHoldsState = (state = initialState, action = {}) => {
    if (!("type" in action) || typeof action.type !== "string") {
        return state;
    }
    switch (action.type) {
        case `${LOGIN}_SUCCESS`:
        case `${LOGOUT}_SUCCESS`:
            return initialState;
        case `${FETCH_PATRON_HOLDS}_PENDING`:
            {
                const instancename = n(action);
                if (instancename === undefined) {
                    return state;
                }
                return {
                    ...state,
                    fetch: _c(state.fetch, instancename, {
                        loading: true,
                        loaded: false,
                        error: null
                    })
                }
            }
        case `${FETCH_PATRON_HOLDS}_SUCCESS`:
            {
                const instancename = n(action);
                if (instancename === undefined || !("result" in action) || typeof action.result !== "object" || action.result === null) {
                    return state;
                }
                const holds = [];
                if ("holds" in action.result && Array.isArray(action.result.holds)) {
                    for (const o of action.result.holds) {
                        const hold = objectToHold(o);
                        if (hold !== null) {
                            holds.push(hold);
                        }
                    }
                }
                return {
                    ...state,
                    holds: _c(state.holds, instancename, holds),
                    fetch: _c(state.fetch, instancename, {
                        loading: false,
                        loaded: true,
                        error: null
                    })
                };
            }
        case `${FETCH_PATRON_HOLDS}_FAIL`:
            {
                const instancename = n(action);
                if (instancename === undefined) {
                    return {
                        ...state,
                        fetch: new Map()
                    };
                }
                const error = "error" in action ? "" + action.error : "unknown error";
                return {
                    ...state,
                    fetch: _c(state.fetch, instancename, {
                        loading: false,
                        loaded: true,
                        error: error
                    })
                }
            }
        case `${PLACE_HOLD}_PENDING`:
            {
                const t = pn(action);
                if (t === undefined) {
                    return {
                        ...state,
                        place: new Map()
                    };
                }
                const [_, k] = t;
                return {
                    ...state,
                    place: _c(state.place, k, {
                        loading: true,
                        error: null
                    })
                }
            }
        case `${PLACE_HOLD}_SUCCESS`:
            {
                const t = pn(action);
                if (t === undefined) {
                    return {
                        ...state,
                        place: new Map()
                    }
                }
                const [instancename, k] = t;
                if ("result" in action && typeof action.result === "object" && action.result !== null
                    && "hold" in action.result && typeof action.result.hold === "object" &&
                    action.result.hold !== null) {
                    const hold = objectToHold(action.result.hold);
                    if (hold === null) {
                        return {
                            ...state,
                            place: _c(state.place, k, {
                                loading: false,
                                error: "Invalid hold object"
                            })
                        };
                    }
                    const holds = state.holds.get(instancename);
                    const hs = holds === undefined ? [hold] : [...Array.from(holds), hold]
                    return {
                        ...state,
                        holds: _c(state.holds, instancename, hs),
                        place: _c(state.place, k, {
                            loading: false,
                            error: null
                        })
                    }
                }
                return {
                    ...state,
                    place: _c(state.place, k, {
                        loading: false,
                        error: "hold missing in response"
                    })
                };
            }
        case `${PLACE_HOLD}_FAIL`:
            {
                const t = pn(action);
                if (t === undefined) {
                    return {
                        ...state,
                        place: new Map()
                    }
                }
                const [_, k] = t;
                const error = "error" in action ? "" + action.error : "unknown error";
                return {
                    ...state,
                    place: _c(state.place, k, {
                        loading: false,
                        error: error
                    })
                }
            }
        case `${REMOVE_HOLD}_PENDING`:
            {
                const t = rn(action);
                if (t === undefined) {
                    return state;
                }
                const [_, k] = t;
                return {
                    ...state,
                    remove: _c(state.remove, k, {
                        loading: true,
                        error: null
                    })
                };
            }
        case `${REMOVE_HOLD}_SUCCESS`:
            {
                const t = rn(action);
                if (t === undefined) {
                    return {
                        ...state,
                        remove: new Map()
                    }
                }
                const [instancename, k] = t;
                if ("result" in action && typeof action.result === "object" && action.result !== null
                    && "hold_id" in action.result && typeof action.result.hold_id === "number") {
                    const hold_id = action.result.hold_id;
                    const holds = state.holds.get(instancename);
                    const newholds = [];
                    if (holds !== undefined) {
                        for (const h of holds) {
                            if (h.hold_id !== hold_id) {
                                newholds.push(h);
                            }
                        }
                    }
                    return {
                        ...state,
                        holds: _c(state.holds, instancename, newholds),
                        remove: _c(state.remove, k, {
                            loading: false,
                            error: null
                        })
                    };
                }
                return {
                    ...state,
                    remove: _c(state.remove, k, {
                        loading: false,
                        error: "hold_id missing in response"
                    })
                };
            }
        case `${REMOVE_HOLD}_FAIL`:
            {
                const t = rn(action);
                if (t === undefined) {
                    return {
                        ...state,
                        remove: new Map()
                    }
                }
                const [_, k] = t;
                const error = "error" in action ? "" + action.error : "unknown error"
                return {
                    ...state,
                    remove: _c(state.remove, k, {
                        loading: false,
                        error: error
                    })
                };
            }
    }
    return state;
};

export default holds;

