import recordSVG from '@plone/volto/icons/record.svg';
import searchSVG from '@plone/volto/icons/spy.svg';
import userSVG from '@plone/volto/icons/user.svg';
import bookSVG from '@plone/volto/icons/book.svg';

import addReducers from './reducers/index';

const applyConfig = (config: any) => {
    config.settings = {
        ...config.settings,
    };
    addReducers(config);
    config.blocks.groupBlocksOrder = [
        { id: 'mostUsed', title: 'Most used' },
        { id: 'text', title: 'Text' },
        { id: 'media', title: 'Media' },
        { id: 'common', title: 'Common' },
        { id: 'yokohama', title: 'Yokohama' },
    ];

    return config;
};

export default applyConfig;
