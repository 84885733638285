/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const GET_YOKOHAMA_RECORD = 'GET_YOKOHAMA_RECORD';
export const GET_YOKOHAMA_SEARCH_TEMPLATES = 'GET_YOKOHAMA_SEARCH_TEMPLATES';
export const PLACE_HOLD = 'PLACE_HOLD';
export const UPDATE_HOLD = 'UPDATE_HOLD';
export const REMOVE_HOLD = 'REMOVE_HOLD';
export const FETCH_PATRON_HOLDS = 'FETCH_PATRON_HOLDS';
export const FETCH_PATRON_DATA = 'FETCH_PATRON_DATA';
