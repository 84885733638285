import { ActionState } from '../../actions/types';

export type Hold = {
    hold_id: number,
    biblio_id: number,
    patron_id: number,
    hold_date: Date | null,
    cancellation_date: Date | null,
    cancellation_reason: string | null,
    waiting_date: Date | null,
    expiration_date: Date | null,
    priority: number,
    status: string | null,
    timestamp: Date | null
}

const dp: (s: string | null | undefined) => Date | null = (s) => {
    if (s === null || s === undefined) {
        return null;
    }
    const v = Date.parse(s);
    if (Number.isNaN(v)) {
        return null;
    }
    return new Date(v);
}

export const objectToHold: (o: object) => Hold | null = (o) => {
    try {
        const o0: any = o;
        return {
            hold_id: o0.hold_id,
            biblio_id: o0.biblio_id,
            patron_id: o0.patron_id,
            hold_date: dp(o0.hold_date),
            cancellation_date: dp(o0.cancellation_date),
            cancellation_reason: o0.cancellation_reason,
            waiting_date: dp(o0.waiting_date),
            expiration_date: dp(o0.expiration_date),
            priority: o0.priority,
            status: o0.status,
            timestamp: dp(o0.timestamp)
        };
    } catch (e) {
        return null;
    }
};

export type PlaceState = {
    loading: boolean,
    error: string | null
};

export const initialPlaceState: PlaceState = {
    loading: false,
    error: null
};

export type PatronHoldsState = {
    holds: Map<string, Hold[]>,
    fetch: Map<string, ActionState>,
    place: Map<string, PlaceState>,
    remove: Map<string, PlaceState>
};

export const initialState: PatronHoldsState = {
    holds: new Map(),
    fetch: new Map(),
    place: new Map(),
    remove: new Map()
};
