const TitleBlockView = ({ properties, metadata }) => {
    if (properties['@type'] === 'marc_mall') {
        return (<></>);
    }
    return (
        <h1 className="documentFirstHeading">
            {(metadata || properties)['title'] || ''}
        </h1>
    );
};

export default TitleBlockView;
