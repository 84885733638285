import patrondata from './yokohama-patron';
import holds from './yokohama-holds';

const addReducers = (config: any) => {
    config.addonReducers = {
        ...config.addonReducers,
        patrondata,
        patronholds: holds
    };
};

export default addReducers;
