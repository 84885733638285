import { defineMessages, useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPatronData } from '../../actions/patron';
import { toast } from 'react-toastify';
import { Toast } from '@plone/volto/components';

import { initialPatronDataState, PatronDataState } from './types';
export * from './types';

const messages = defineMessages({
    fetchPatronDataFailed: {
        id: 'yokohama-patron-data-fetch-failed',
        defaultMessage: 'Failed to fetch patron data',
    },
    fetchPatronDataFailedText: {
        id: 'yokohama-patron-data-fetch-failed-text',
        defaultMessage: 'Failed to fetch patron data: {error}'
    }
})

const TOAST_ID = 'fetchPatronDataFailed';

export const usePatronData = () => {
    const pd: any = useSelector((state) => "patrondata" in state ? state.patrondata : initialPatronDataState);
    const intl = useIntl();
    const patronData: PatronDataState = pd
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) =>
        "userSession" in state
        && typeof state.userSession === "object"
        && state.userSession !== null
        && "token" in state.userSession
        && state.userSession.token !== null
        && state.userSession.token !== undefined
    );

    useEffect(() => {
        if (isLoggedIn && !patronData.fetch.loaded && !patronData.fetch.loading) {
            dispatch(fetchPatronData());
        }
        if (patronData.fetch.error === null) {
            toast.dismiss(TOAST_ID);
        } else {
            if (!toast.isActive(TOAST_ID)) {
                toast.error(
                    Toast({
                        error: true,
                        title: intl.formatMessage(messages.fetchPatronDataFailed),
                        content: intl.formatMessage(messages.fetchPatronDataFailedText, { error: patronData.fetch.error })
                    }),
                    { toastId: TOAST_ID },
                );
            }
        }

    });

    return patronData;
};
